@font-face {
  font-family: "Light";
  src: local("GreycliffCF-Light"),
    url("./fonts/GreycliffCF-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Medium";
  src: local("GreycliffCF-Medium"),
    url("./fonts/GreycliffCF-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "ExtraBold";
  src: local("GreycliffCF-ExtraBold"),
    url("./fonts/GreycliffCF-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Heavy";
  src: local("GreycliffCF-Heavy"),
    url("./fonts/GreycliffCF-Heavy.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Regular";
  src: local("GreycliffCF-Regular"),
    url("./fonts/GreycliffCF-Regular.ttf") format("truetype");
  font-display: swap;
}

#input_11,
#input_13 {
  -webkit-appearance: menulist;
}

.App {
  color: #0E081A;
}

p {
  font-size: 1em;
  line-height: 1.45em;
  color: #4A325B;
  font-family: "Medium";
}

h6 {
  font-family: "ExtraBold";
  font-size: 1.25em;
  color: #0E081A;
  letter-spacing: -0.01em;
  margin: 0px;
}

h1 {
  font-size: 2.65em;
  line-height: 1.1em;
  font-family: "ExtraBold";
  margin: 0.1em;
  letter-spacing: -0.01em;
}

h2 {
  font-family: "Regular";
  font-size: 1.8em;
  line-height: 1em;
  letter-spacing: -0.01em;
}


h4 {
  font-family: "Regular";
  font-size: 1.2em;
  line-height: 1.4em;
  letter-spacing: -0.01em;
  color: #4A325B;
}

h5 {
  font-family: "Regular";
  font-size: 1.15em;
  line-height: 1.45em;
  letter-spacing: -0.01em;
}

.container {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 1em;
  color: #0E081A;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-lila-oscuro-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;

}

.nav {
  position: fixed;
  z-index: 10;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100dvw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.container-nav-r {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.padding-navbar {
  padding: 0.938em;
}

.margin-navbar {
  margin: 2em;
}

.logo {
  height: 1.875em;
  transition: 0.3s ease;
}

.menu {
  height: 0.875em;
}

.menu-container {
  position: relative;
  background: #FECC3F;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}

.menu-container:hover {
  background: #AD80CE;
}

.m-open {
  background: #E3D6EA;
}

.m-close {
  background: #FECC3F;
}

.menu-toggler {
  cursor: pointer;
}

.nav-overlay {
  position: fixed;
  z-index: -2;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.container-secciones {
  height: 80%;
  width: 100%;
  text-align: left;
  color: #0E081A;
  letter-spacing: -0.01em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

ul,
li {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-family: "Heavy";
  font-size: 1.75em;
  line-height: 1.45em;
  letter-spacing: -0.01em;
  color: #0E081A;
}

ul {
  margin-left: 1.25em;
}

li:visited {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #0E081A;
}

li:active {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #AD80CE;
}

li:hover {
  transition: 0.3s ease;
  color: #AD80CE;
}

a {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: #0E081A;
}

.container-social {
  height: 20%;
  width: 100%;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: #0E081A;
  letter-spacing: -0.01em;
  text-decoration: none;

}

.social-text-wrapper {
  padding: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-icons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2em;
}

.social-icon {
  background: #ffffff;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  transition: 0.3s ease;
}

.social-icon:hover {
  background: #E3D6EA;
}

.icon-s {
  height: 32px;
}

button,
select {
  text-transform: none;
  background: rgba(0, 0, 0, 0.005);
  border: none;
  width: 52px;
  height: 44px;
  padding: .1em;
  font-family: "ExtraBold";
  color: #4A325B;
}

.color-a {
  color: #0E081A;
}

.color-b {
  color: #AD80CE;
}


button,
select:focus-visible {
  outline: none;
}

.mobile-hidden {
  display: block;
}

.mobile-show {
  display: none;
}

.sidebar {
  position: fixed;
  z-index: 3;
  height: 100vh;
  height: 100dvh;
  width: 108px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sidebar-text {
  color: #4A325B;
  transform: rotate(90deg);
  font-size: 0.8em;
  letter-spacing: -0.01em;
  text-align: center;
  align-self: center;
  width: 500px;
}

.sidebar-btn {
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 0;
  padding-bottom: 1em;
}


.chat-btn {
  margin: 2em;
}


/*Home*/

.semi-circle {
  width: 110dvw;
  height: 55dvw;
  background-color: #DAC6E1;
  border-radius: 55dvw 55dvw 0 0;
  transform: translate(0%, 35%);
}

.background-c2 {
  background-color: #E3D6EA;
}

.front-content {
  position: absolute;
  height: 100dvh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
}

.hero-img {
  height: 320px;
}

.icon-arrow {
  background: #E3D6EA;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

/*Slider*/
.swiper,
.swiper-container {
  width: 100dvw;
  height: 80dvh;
  /* Center slide text vertically */
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  height: auto !important;
}

.swiper-pagination-bullet {
  background: #AD80CE !important;
  margin: 0.5em !important;
}

.swiper-pagination-bullet-active {
  transform: scale(1.5);
}

.swiper-pagination {
  animation: fadeInAnimation 2.5s ease-out 0s normal forwards 1;
  animation-iteration-count: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*Banner*/

.banner {
  height: 30dvh;
  background: #AD80CE;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100dvw;
}

.banner-text {
  color: #FECC3F;
  font-size: 6em;
  letter-spacing: -0.01em;

}

/* Section Home*/

.section-home {
  width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  text-align: center;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 2em;
}

.section-text {
  font-family: "Regular";
  font-size: 1.5em;
  line-height: 1.5em;
  width: 60dvw;
  letter-spacing: -0.01em;
  margin-left: 0.8em;
  margin-right: 0.8em;
}

.margin-left-mobile {
  margin-left: 2em;
}

.section-home-2 {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #0E081A;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-lila-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  padding-bottom: 2em;
  padding-top: 2em;
}


.text-section-home {
  color: #4A325B;
  text-align: left;
  width: 40dw;
  width: 40dvw;
  align-self: flex-start;
  margin-left: 10vw;
  margin-left: 10dvw;
  margin-top: 2em;
}

.img-text-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100dw;
  width: 100dvw;
}

.section-home-img {
  height: 320px;
}

.container-img-section {
  width: 40dw;
  width: 40dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.text-section-home-2 {
  color: #4A325B;
  text-align: left;
  width: 40dw;
  width: 40dvw;
  align-self: center;
  margin: 0.5em;
}

.text-block {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.check {
  color: #AD80CE;
  font-family: "ExtraBold";
}

.check-list li {
  color: #0E081A;
  font-family: "ExtraBold";
  font-size: 0.8em;
  display: inline;
  margin: 1em;
}

.check-list {
  margin: 0;
  margin-top: 2em;
}

.section-home-3 {
  width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E3D6EA;
  text-align: center;
  flex-direction: column;
}

.text-section-home-3 {
  color: #4A325B;
  text-align: center;
  width: 40dw;
  width: 40dvw;
  align-self: center;
  margin: 0;
}

.white-bg {
  background: white;
}

.black-bg {
  background: black;
}

.lila-bg {
  background: #E3D6EA;
}

.bold-h2 {
  color: #0E081A;
  font-family: "Heavy";
  width: 80dw;
  width: 80dvw;
}

.bold {
  font-family: "Heavy";
}

/*Button*/

.yellow {
  outline: none;
  cursor: pointer;
  border: none;
  width: 200px;
  height: 60px;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-family: "Heavy";
  font-size: 1em;
  border-radius: 500px;
  overflow: hidden;
  background: white;
  color: #0E081A;
}

.yellow span {
  position: relative;
  z-index: 5;
  transition: color 0.4s;
}

.yellow:hover span {
  color: #AD80CE;
}

.yellow::before,
.yellow::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.yellow::before {
  content: "";
  background: #FECC3F;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.yellow:hover::before {
  transform: translate3d(100%, 0, 0);
}

.container-services-home {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 2em;
  background: #E3D6EA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #0E081A;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-blanco-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
}

/*Tabs*/

.container-tabs {
  width: 80dw;
  width: 80dvw;
  margin: 0 auto;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab-button::before {
  content: "";
  background: none;
  width: 0%;
  left: 0%;
  transform: skew(0deg);
  transition: transform 0.3s cubic-bezier(0.3, 1, 0.8, 1);

}

.tab-button {
  background-color: #E3D6EA;
  border: none;
  color: #0E081A;
  cursor: pointer;
  padding: 20px;
  margin: 10px;
  width: 100%;
  height: 140px;
  border-radius: 15px;
  transition: all 0.5s ease-out;
  font-size: 1.3em;
  font-family: "ExtraBold";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
}

.tab-img {
  margin-bottom: 0.5em;
  height: 30px;
}

.tab-button:hover {
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.15);
}

.tab-button:disabled {
  background: white;
  color: #0E081A;
}

.title {
  color: black;
  font-size: medium;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.tab-content {
  padding: 50px 80px;
  background-color: none;
  font-weight: "Regular";
  line-height: 1.2em;
  font-size: 1.1em;
  text-align: left;
}

/*Tab content*/

.number-img {
  height: 40px;
  margin-top: 1.5em;
  display: block;
}

.tab-content-btn {
  outline: none;
  cursor: pointer;
  border: none;
  width: 140px;
  height: 50px;
  padding: 0.6rem 1.2rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-family: "Heavy";
  font-size: 1em;
  border-radius: 500px;
  overflow: hidden;
  background: #ffffff;
  color: #ffffff;
  
}

.tab-content-btn span {
  position: relative;
  z-index: 5;
  transition: color 0.4s;
  font-family: "Light";
}

.tab-content-btn:hover span {
  color: #AD80CE;
}

.tab-content-btn::before,
.tab-content-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.tab-content-btn::before {
  content: "";
  background: #AD80CE;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.tab-content-btn:hover::before {
  transform: translate3d(100%, 0, 0);
}

.tab-content-title {
  font-family: "Heavy";
  color: #4A325B;
}

/* Footer*/

.footer {
  text-align: center;
  padding: 2em;
  background: #DAC6E1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.cta-footer {
  width: 80vw;
  width: 80dvw;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.no-border {
  border: none;
}

.links-footer {
  padding-top: 2em;
  padding-bottom: 2em;
  width: 80vw;
  width: 80dvw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

.footer-list {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

footer a {
  color: #4A325B;
}

.footer-list-element {
  color: #4A325B;
  font-family: "ExtraBold";
  font-size: 1.25em;
  letter-spacing: -0.01em;
  margin: 0px;
}

.footer-social-container {
  padding-top: 2em;
  padding-bottom: 2em;
  width: 80vw;
  width: 80dvw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.no-padding {
  padding: 0px;
}

.arrow-wrapper {
  height: 80px;
}

/* About*/
.about-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.h1-big-outline {
  color: #E3D6EA;
  font-size: 18em;
  text-shadow: 0 0 3px #4A325B, 0 0 3px #4A325B, 0 0 3px #4A325B, 0 0 3px #4A325B;
  margin: 0;
  margin-left: 32px;
}


.mini-sub {
  color: #4A325B;
  margin-left: 1em;
  margin-right: 1em;
}

.line {
  height: 1px;
  width: 80px;
  background: #4A325B;
}

.mini-container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
}

.about-subtitle {
  align-self: center;
  margin-top: 0.5em;
  width: 70vw;
  width: 70dvw;
  text-align: center;
}

.subtile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 32px;
  margin-bottom: 32px;
  width: 100dvw;
}

.about-container {
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #0E081A;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-morado-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
}

.about-margin {
  margin: 2em;
  margin-top: 4em;
  margin-bottom: 0;
}

.about-text {
  color: #ffffff;
  text-align: center;
  font-size: 2em;
  line-height: 2.12em;
}

.about-margin-text {
  margin-bottom: 2em;
}

.line-100 {
  height: 1px;
  width: 100%;
  background: #E3D6EA;
  align-self: center;
}

.about-flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  width: 100dvw;
}


.about-img-container {
  width: 30vw;
  width: 30dvw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.about-img {
  height: 420px;
}

.about-manifest {
  color: #E3D6EA;
  width: 30vw;
  width: 30dvw;
  line-height: 1.2em;
  text-align: left;
  margin-bottom: 2em;
}

.chipa-recipe {
  width: 60vw;
  width: 60dvw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20dvw;
}

.recipe-text {
  color: #FECC3F;
  margin-bottom: 1em;
}

.recipe-text-w {
  color: white;
  margin-bottom: 1em;
}

.recipe-ingredient {
  color: #E3D6EA;
  font-family: 'Regular';
  font-size: 1.2em;
  line-height: 1.45em;
  list-style-type: circle;
}

.recipe-ingredient:hover {
  transition: none;
  color: #E3D6EA;
}

.recipe-ingredient:active {
  list-style-type: circle;
  text-decoration: none;
  color: #E3D6EA;
}

.list-ingredients {
  margin-bottom: 1em;
}

.prep-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}

.prep-text {
  color: #ffffff;
  margin-left: 1em;
}

.about-flex-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4em;
}

.about-cta-margin {
  margin-bottom: 1em;
}

/*Team*/

.team-text {
  text-align: center;
  margin: 2em;
}

.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100dvh;
  background: #ffffff;
  padding-top: 4em;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-lila-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
}

.team-card-container {
  margin-bottom: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.card-container {
  display: flex;
  width: 25vw;
  width: 25dvw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 40px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  /* Shadow-light */
  box-shadow: 0px 15px 40px -7px rgba(227, 214, 234, 0.35);
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.img-card {
  width: 100%;
  align-self: center;
}

.card-text {
  font-family: "Heavy";
  color: #0E081A;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 0.25em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.card-job {
  margin: 0;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.card-social {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.round-cicle {
  background: #E3D6EA;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25em;
}


/*Services*/
.container-services {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #0E081A;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-image: url("../src/img/semi-circle-lila-oscuro-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  padding-top: 20dvh;
}

.services-title {
  align-self: center;
}

.services-detail-container {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #4A325B;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-color: #FFFFFF;
  background-image: url("../src/img/semi-circle-lila-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  padding-top: 4em;
  padding-bottom: 4em;
}

.section-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.service-img {
  height: 360px;
}

.service-cont {
  width: 40vw;
  width: 40dvw;
}

.service-cont-2 {
  width: 80vw;
  width: 80dvw;
}

.margin-cta {
  margin-top: 2em;
}

/* Contact*/

.contact-title {
  align-self: center;
}

.container-contact {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #4A325B;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-color: #E3D6EA;
  background-image: url("../src/img/semi-circle-morado-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  padding-top: 4em;
  padding-bottom: 4em;
}

.chipa-title {
  height: 100px;
}

.container-contact-form {
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #4A325B;
  letter-spacing: -0.01em;
  overflow: hidden;
  background-color: white;
  background-image: url("../src/img/semi-circle-lila-bg.svg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  padding-top: 4em;
  padding-bottom: 4em;
}

.flex-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  width: 100dvw;
  min-height: 100vh;
  min-height: 100dvh;
}

.contact-info {
  width: 40vw;
  width: 40dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-mail {
  font-family: "heavy";
  margin-bottom: 1em;
  color: #AD80CE;
  font-size: 2em;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  margin-top: .25em;
}

.contact-text {
  font-family: "heavy";
  font-size: 2em;
  margin-bottom: .25em;
}

.contact-form {
  width: 40vw;
  width: 40dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.contact-img {
  height: 400px;
  align-self: center;
  margin-bottom: 1em;
}

.flex-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.input-style {
  width: 300px;
  border: none;
  min-height: 44px;
  padding: .5em;
  margin-bottom: 1em;
  background: none;
  border-bottom: 1px solid #4A325B;
  font-size: 1.5em;
}

input:focus-visible {
  outline: none;
}

textarea:focus-visible {
  outline: none;
}

label {
  color: #4A325B;
  font-size: 1em;
}

.contact-button {
  align-self: center;
}

.text-contact {
  align-self: center;
  margin-top: 2em;
}

.phone {
  font-size: 1.2em;
  margin-top: 0.15em;
  margin-bottom: 0.15em;
}

/*Media Queries*/
@media (width <=768px) {
  /* styles for viewports <= 320px */

  .container-secciones {
    height: 70%;
    justify-content: flex-end;
  }

  .container-social {
    height: 30%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .social-icons-wrapper,
  .social-text-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .social-text-wrapper {
    text-align: center;
    padding-bottom: 1em;
  }

  h6 {
    font-size: 1em;
  }

  p {
    font-size: 0.8em;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1em;
  }

  li {
    font-size: 1.35em;
    line-height: 1.45em;
  }

  .hero-img {
    height: 220px;
  }

  .section-home-img {
    height: 160px;
  }


  .semi-circle {
    transform: translate(0%, 0%);
  }

  .banner-text {
    font-size: 1.25em;
  }

  .banner {
    height: 15dvh;
  }

  .section-text {
    font-size: 1.2em;
    line-height: 1.5em;
    width: 80dvw;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .margin-left-mobile {
    margin-left: .8em;
  }

  h4 {
    font-family: "Regular";
    font-size: 0.9em;
    line-height: 1.4em;
    letter-spacing: -0.01em;
    margin: 0;
    color: #4A325B;
  }

  .text-section-home {
    text-align: center;
    width: 80dw;
    width: 80dvw;
    align-self: center;
    margin-left: 0vw;
    margin-left: 0dvw;
    margin-top: 0.3em;
  }

  .img-text-flex {
    flex-direction: column;
    width: 100dw;
    width: 100dvw;
  }

  .container-img-section {
    margin-top: 0.3em;
  }

  .text-section-home-2 {
    text-align: center;
    width: 80dw;
    width: 80dvw;
    align-self: center;

  }

  .check-list li {
    color: #0E081A;
    font-family: "ExtraBold";
    font-size: 0.5em;
    display: block;
    margin: 0.5em 0em;

  }

  .check-list {
    margin-top: 0.3em;
  }

  .text-block {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .container {
    background-attachment: unset;
    background-position: center;
  }

  .section-home-2 {

    background-attachment: unset;
    background-position: center;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tab-button {
    flex-direction: row;
    height: 50px;
    font-size: 1em;
  }

  .tab-img {
    margin-bottom: 0;
    margin-right: 10px;
    height: 18px;
  }

  .tab-content-btn {
    font-size: .8em;
    width: 120px;
    height: 40px;
    padding: 0.4rem 1rem;
  }

  .cta-footer {
    flex-direction: column;
    align-items: center;
  }


  .footer-list-element {
    font-size: 1em;
  }

  .footer-list {
    flex-direction: column;
    align-items: center;
  }

  .footer-social-container {
    flex-direction: column;
    align-items: center;
  }

  .h1-big-outline {
    font-size: 3.2em;
    margin-left: 0;
    text-shadow: 0 0 1px #4A325B, 0 0 1px #4A325B, 0 0 1px #4A325B, 0 0 1px #4A325B;
  }


  .about-subtitle {
    width: 80dvw;
    text-align: center;
    align-self: center;
  }

  .line {
    width: 40px;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .subtile-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-bottom: 32px;
  }

  .about-title {
    align-items: center;
    justify-content: center;
  }

  .mini-container {
    align-self: center;
    margin-left: 0;
  }

  .about-text {
    color: #ffffff;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.45em;
  }

  .about-flexbox {
    flex-direction: column;
    justify-content: center;
  }

  .about-manifest {
    color: #E3D6EA;
    width: 80vw;
    width: 80dvw;
    text-align: center;
  }

  .about-img-container {
    width: 80vw;
    width: 80dvw;
  }

  .about-img {
    height: 320px;
  }

  .recipe-ingredient {
    color: #E3D6EA;
    font-family: 'Regular';
    font-size: 0.8em;
    line-height: 1.4em;
    list-style-type: circle;
  }

  .chipa-recipe {
    width: 80vw;
    width: 80dvw;
    margin-left: 10vw;
    margin-left: 10dvw;
  }

  .container-tabs {
    width: 80vw;
    width: 80dvw;
  }

  .text-section-home-3 {
    width: 80dw;
    width: 80dvw;
  }

  .tab-content {
    padding: 2em;
  }

  .team-card-container {
    margin-bottom: 4em;
    flex-direction: column;
  }

  .card-container {
    display: flex;
    width: 80vw;
    width: 80dvw;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .service-img {
    height: 260px;
    align-self: center;
  }

  .service-cont {
    width: 80vw;
    width: 80dvw;
  }

  .chipa-title {
    height: 30px;
  }

  .flex-contact {
    flex-direction: column;
  }

  .contact-img {
    height: 280px;
  }

  .contact-info {
    width: 80vw;
    width: 80dvw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-mail {
    font-size: 1.2em;
  }

  .contact-text {
    font-size: 1.2em;
  }

  .contact-form {
    width: 80vw;
    width: 80dvw;
  }

  .input-style {
    width: 200px;
  }

  .container-contact {
    background-image: none;
    background: #4A325B;
  }

  .container-contact-form {
    background-image: none;
  }

  .services-detail-container {
    background-image: none;
  }

  .about-container {
    background-image: none;
  }

  .section-home-3{
    height: 60vh;
    height: 60dvh;
  }

}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .banner-text {
    font-size: 4em;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  select {
    -webkit-appearance: menulist;
  }

  .tab-button {
    flex-direction: row;
    height: 50px;
    font-size: 1em;
  }

  .tab-img {
    margin-bottom: 0;
    margin-right: 10px;
    height: 18px;
  }

  .container-tabs {
    width: 80vw;
    width: 80dvw;
  }

  .text-section-home-3 {
    width: 80dw;
    width: 80dvw;
  }

  .tab-content {
    padding: 2em;
  }

  .h1-big-outline {
    font-size: 8em;
  }

  .about-subtitle {
    width: 80dvw;
  }

  .hero-img {
    height: 300px;
  }

  .chipa-title {
    height: 60px;
  }
  .container-contact {
    background-image: none;
    background: #4A325B;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1515px) {
  .h1-big-outline {
    font-size: 12em;
  }
}



@media (height <=600px) {

  .icon-arrow {
    width: 30px;
    height: 30px;
  }

  .hero-img {
    height: 140px;
  }

  .about-subtitle {
    width: 80vw;
    width: 80dvw;
  }
}

@media (height >=1080px) {
  .banner {
    height: 20vh;
    height: 20dvh;
  }

  .section-home {
    min-height: 100vh;
    min-height: 100dvh;
  }

  .section-home-2 {
    min-height: 60vh;
    min-height: 60dvh;
  }

  .section-home-3 {
    min-height: 50vh;
    min-height: 50dvh;
  }
}